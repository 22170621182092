import React from 'react'

function Banner() {
    return (
        <React.Fragment>
            <div className="p-5 bg-image text-center">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-container">
                                <h1 className='text-light display-4'>Agusan Del Norte Electric Cooperative, Inc.</h1>
                                <br />
                                <span className='text-light fs-4 font-weight-500'>Butuan City</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Banner