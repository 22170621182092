import React from 'react'
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';

function Navbar() {

    return (
        <Menubar
            start={<h4 style={{textTransform: "uppercase"}}>Agusan Del Norte Electric Cooperative</h4>}
            end={
                <div>
                   {/* <Button className='me-3 p-button-text fw-bold'><span className='login'>About</span></Button> */}
                 {/* <Button className='me-3 p-button-text fw-bold'><span className='login'></span></Button> */}
                </div>
            }
        />
    )
}

export default Navbar