import React from 'react'
import Navbar from '../navbar/Navbar'
import Banner from '../banner/Banner'

function Home() {
    return (
        <React.Fragment>
            <Navbar></Navbar>
            <Banner></Banner>
        </React.Fragment>
    )
}

export default Home